.component-header{
    .bar{
        width:6px;
    }
    .font-fmedium{
        cursor: pointer;
    }
    .title-container{
        flex:28%;
    }
    .search-bar-container{
        flex:46%;
        max-width:550px;
        .theme-input{
            background:rgba(#fff,0.3);
        }
        .search-result-container{
            z-index: 100;
            max-height:380px;
            height:auto;
            overflow:hidden;
        }
    }
    .result{
        max-height:300px;
        height:auto;
        overflow:auto;
    }
    .icon-container{
        flex:28%;
    }
    .image-container{
        width:12%;
    }
    .info-container{
        // width:88%;
    }
}
.autcomplete-loader{
    text-align: center !important;
}