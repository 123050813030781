$fb:#3d578f;
$gp:#d62628;
$t:#28aae1;
$li:#0a78b7;
$website:#1e56ff;

.social-icon{
    height:20px;
    width:20px;
    color:white;
    font-size:12px;
}

.facebook{
    background: $fb;
    transition:all 0.3s;
    cursor: pointer;
    &:hover{
        color:$fb;
        background:white;
        border:2px solid $fb;
    }
}
.twitter{
    background: $t;
    transition:all 0.3s;
    cursor: pointer;
    &:hover{
        color:$t;
        background:white;
        border:2px solid $t;
    }
}
.linkedin{
    background: $li;
    transition:all 0.3s;
    cursor: pointer;
    &:hover{
        color:$li;
        background:white;
        border:2px solid $li;
    }
}
.google-plus{
    background: $gp;
    transition:all 0.3s;
    cursor: pointer;
    &:hover{
        color:$gp;
        background:white;
        border:2px solid $gp;
    }
}

.website{
    background: $website;
    transition:all 0.3s;
    cursor: pointer;
    &:hover{
        color:$website;
        background:white;
        border:2px solid $website;
    }
}
a.disabled {
    pointer-events: none;
    cursor: not-allowed;
 }