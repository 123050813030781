.gcc-container-map{
    top: 140px;
    width: 320px;
    right:20px;
}
.gcc-container-list{
    top: 140px;
    width: 320px;
    /* right: 20px; */
    transform: translateX(-50%);
    left: 50%;
    position: relative;  
}
.news-container{
    max-height:300px;
}
.alert-company-name{
    background: #3da5fe;
    max-width:160px;
    white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.gcc-news-section{
    border-radius:2px;
}
.gcc-label{
    font-size: 14px;
    font-weight: bold;
    color: #2e3840;
}
.container-heading{
    margin-bottom:10px
}
.text-gcc{
    color: #2e3840;
}