.css-15k3avv{
    z-index:1000 !important;
}

.css-vj8t7z,.css-2o5izw{
    min-height:30px !important;
    max-height:30px;
}
.subfilter-dropdown{
    max-width:40%;
}
.filter-content{
   > div{
        // flex:1;
        min-width:150px;
    }
}
.filter-name{
    max-width:300px;
    text-overflow: ellipsis;
}

.input-box{
    height:30px !important;
    width:150px;
    input{
        height:100%;
        border:1px solid gainsboro;
        border-radius:4px;
        height:30px;
        padding:0 5px;
    }
}
.async-multi-select{
    .css-1wy0on6{
        display: none;
    }
}
.react-datepicker-wrapper{
border-radius:4px;
padding:7px;
border:1px solid gainsboro;
margin-right:4px;

}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
    background-color:#1f63fe !important;
}