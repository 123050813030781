.component-company{
    .gcc-container-list{
        width:500px;
        // position: static;
        .news-container{
            max-height:500px;
        }
    }
    .alert-backdrop{
        background:rgba(#000,0.5);
        position:fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top:0;
        left:0;
        width:100%;
        height:100%;
        visibility:hidden;
        z-index:10;
        transition:all 0.3s;
        &.active{
            visibility: visible;
        }
    }
    .list-container{
        height:calc(100vh - 220px);
        overflow-x: auto; 
        border-radius:10px;
    }
    .filter-container{
        width:250px;
    }
    .flex-between{
        z-index:99;
        position: relative;
    }
    .square-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        .column-image{
            content:url('../../static/images/column.png');
            transition:0.3s;
        }
        &:hover{
            .column-image{
                content:url('../../static/images/column-white.png');
            }
        }
    }
    
}
.basic-dropdown{
    margin-left:0 !important;
    margin-right:10px;
    margin-top:10px;
    margin-bottom:10px;
}
.filter-section{
    min-height:200px;
    max-height: 250px;
    // padding-bottom:250px;
    overflow: auto;
}
.gcc-active{
    background:rgba(#000,0.3);
    opacity: 0.5;
    transition:all 0.3s;
}

