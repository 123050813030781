.component-map {
    height: calc(100vh - 75px);
    margin: 0;
    padding: 0;
    margin-top: -65px;
    margin-left: -32px;
    margin-right: -32px;
    position: relative;
    z-index: 0;
}

.back-btn {
    position: absolute !important;
    bottom: 30px !important;
    left: 28px !important;
    .chevron-btn {
        display: inline-block;
        cursor: pointer;
        text-align: center;
        padding: 10px 24px;
        text-overflow: ellipsis;
        letter-spacing: 0.8px;
        font-size: 12px;
        &.active {
            background: #3da5fe;
        }
        &.right-arrow {
            position: relative;
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 80px;
                top: 0; // z-index: 2;
                height: 100%;
                clip-path: polygon(0 0, 20% 53%, 0 100%, 0 100%, 0 52%, 0 0);
            }
            &:before {
                left: -1px;
                background: white;
            }
            &:after {
                right: -79px;
                background: #3da5fe;
            }
        }
    }
}

.city-bg {
    background: rgba(151, 151, 151, 0.3);
}

.custom-marker-icon {
    height: 50px;
    width: 50px;
    color: white;
    border-radius: 100px;
    text-align: center;
    border: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-on-marker {
    padding: 10px;
}

.sector-item {
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 3px 0;
}

.company-count {
    font-size: 12px;
    min-width: 40px;
}

.industry-name {
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
}

.marker-label {
    width: 66px;
    height: 15px;
    font-family: HelveticaNeue;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2e3840;
}

.content-popup::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

.maker-label-name {
    width: 100px;
    height: 15px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    margin-top: 15px;
    text-transform: uppercase;
    margin-left: -22px;
}

.content-popup-box {
    max-height: 200px; // padding:5px 10px;
    padding-top: 30px; // border-radius: 5px;
    .loc-type-detail {
        position: absolute;
        width: 100%;
        padding: 5px 50px 5px 10px;
        background: white;
        left: 0;
        top: 0;
        z-index: 5;
        border-bottom: 1px solid #f2f2f2;
        .loc-type {
            margin-bottom: 0.25rem;
        }
    }
}

.gm-style-iw {
    //   bottom: 0 !important;
    //   top: unset !important;
    //  left:0 !important;
    //  > div{
    //   background-color:rgba(#fff, 0.9) !important;
    //   box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    //   border-radius:4px;
    //   margin-bottom:15px;
    //   &:after{
    //     content:'';
    //     position: absolute;
    //     bottom:5px;
    //     left:50%;
    //     height:15px;
    //     width:30px;
    //     background-color:rgba(#fff, 0.9) ;
    //     clip-path: polygon(50% 100%, 0 0, 100% 0);
    //     box-shadow: 3px 4px 0px 0px rgba(0,0,0,0.25);
    //   }
    //  }
}

.gm-ui-hover-effect {
    top: 4px !important;
    right: 2px !important;
    border-radius: 100px;
    z-index: 10;
    background: white !important;
    width: 28px !important;
    height: 28px !important;
    background: rgba(0, 0, 0, 0.15) !important;
    img {
        margin: 5px 7px !important;
    }
} // ........bottom chevron
#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1)>div:nth-child(3) {
    top: 125px !important;
    display: none;
}

// ........mask
#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1)>div:nth-child(2) {
    border-radius: 5px !important;
    border: 0 !important; // max-height:150px !important;
    width: 0 !important;
    background-color: rgba(#fff, 0.9) !important;
}

// ........popup position
#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1) {
    // top: 10px !important;
}

// .......main container
#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1)>div:nth-child(4) {
    // border-radius: 5px !important; // top: -320px !important;
    // background-color: transparent !important;
    // border: 0 !important;
}

#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1)>div:nth-child(3)>div:nth-child(2)>div {
    background-color: rgba(#fff, 0.6) !important;
}

#map>div>div>div:nth-child(1)>div:nth-child(3)>div>div:nth-child(4)>div>div:nth-child(1)>div:nth-child(1) {
    border-top: 0 solid transparent !important;
}

//  #map > div > div > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) {
//   height:12px !important;
//   border-color:transparent !important;
//   > div{
//     transform: skewX(50.6deg) !important;
//     width: 18px !important;
//     height: 12px !important;
//     left:0 !important;
//     background-color:rgba(#fff, 0.6)!important;
//   }
//  }
//  #map > div > div > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2){
//   height:12px !important; 
//   > div{
// transform: skewX(-50.6deg) !important;
//   height: 12px !important;
//   width: 14px !important;
//   background-color:rgba(#fff, 0.6)!important;
// }
//   }''
.filter-container {
    width: 250px;
}

.map-fltr {
    float: right;
    z-index: 20;
    position: relative;
    margin-top: -550px;
}