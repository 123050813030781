.component-accordion-dropdown{
    max-height:330px;
    overflow: auto;
    // margin-top:35px;
    .u-position-relative{
    font-weight:400;
    }
    .accordion-title-container{
        border-top: 0;
        .accordion-title{
            font-weight: 400;
        letter-spacing:0.6px;
            &:focus{
                outline:none;
            }
        }
    }
      
        .accordion-body{
            .filter-name{
        transition:all 0.3s;
            }
        }
        .accordion__arrow {
            display: inline-block;
            position: relative;
            width: 19px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -6px;
            &:after, &:before{
                display: block;
                position: absolute;
                top: 50%;
                width: 7px;
                height: 1px;
                background-color: currentColor;
                content: '';
            }
        }
    
    
    
}