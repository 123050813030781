.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.enabled {
    .icon-height{
       width: 16px !important;
       height: 16px !important; 
    }
}
