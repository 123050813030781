.component-table-grid{
    border-radius:10px;
}

.ag-theme-balham {
    border-radius:10px;
    .ag-header {
        min-height:50px!important;
    border-color:rgba(222, 222, 222, 0.4)!important;
        .ag-header-row{
            height:50px !important;
            color:#000 !important;
            font-size:14px;
            font-family: HelveticaNeueMedium;
            letter-spacing:0.5px;
            // text-transform: capitalize;
            border-color:rgba(222, 222, 222, 0.4)!important;
        }
    }
    .ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell{
        line-height:50px !important;
    }
    .ag-root{
        border:0;
        .ag-header-row{
            background:white;
        }
}
.ag-body-container .ag-layout-normal{
    font-family: HelveticaNeueMedium !important;
}
}
.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell{
    line-height:50px !important;
}
.ag-theme-balham .ag-row{
    border-color:rgba(222, 222, 222, 0.4)!important;
    font-family: HelveticaNeueNormal;
    letter-spacing: 1px;
    color: #2e3840;
    font-size:12px;
    font-weight: bold;
}
.ag-theme-balham .ag-ltr .ag-cell-focus {
    border: 1px solid transparent;
    outline: medium none invert;
    outline: initial;
}

// heading side vertical bar
.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
    height: 30px !important;
}
.ag-row{
    cursor:pointer;
}

.ag-theme-balham .ag-row-odd {
    background-color: #fff !important;
}

.cell-wrap-text {
    white-space: normal !important;
}
.container {
    display: grid;
    grid-template-columns: 0fr 2fr 0fr;
    grid-template-rows: 50vh;
    width: 100%;
}
.ag-header-container{
    background:#fff;
}