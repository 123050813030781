$hero: #1f63fe;
$divider: #d8d8d8;
$body-text: #2e3840;

.component-tab{
    // overflow:hidden;
    display:block;
    overflow: auto;
         white-space: nowrap;

         &::-webkit-scrollbar{
            display: none;
        }
    .theme-tab{
         border-radius:4px;
         overflow:hidden;
         width:auto;
         border:1px solid $hero;
         background:white;
         
         .tab{
             display: inline-block;
            list-style-type: none;
            padding:10px 12px;
            color:$hero;
            outline:none;
            background:white;
            cursor:pointer;
            letter-spacing:2;
            font-size: 12px;
            text-transform: capitalize;
            border-left:1px solid $hero;
            transition:all 0.3s;
            &:nth-child(1){
                border-left: 0;
            }
            &.active, &:hover{
                background:$hero;
                color:white;
            }
        }
}

}