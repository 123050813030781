.gcc-container-map{
    top: 150px;
    width: 320px;
    right:20px;
}
.gcc-container-list{
    top: 150px;
    width: 320px;
    /* right: 20px; */
    transform: translateX(-50%);
    left: 50%;
    position: relative;  
}
.news-container{
    max-height:300px;
}
.alert-company-name{
    background: #3da5fe;
    max-width:160px;
    white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.gcc-news-section{
    border-radius:2px;
}
.gcc-label{
    font-size: 14px;
    font-weight: bold;
    color: #2e3840;
}
.container-heading{
    margin-bottom:10px
}
.text-gcc{
    color: #2e3840;
}
.react-tabs__tab.react-tabs__tab--selected{
    background: #1f63fe;
    color: white;
}


.react-tabs__tab{
display: inline-block;
list-style-type: none;
padding: 10px 12px;
color: #1f63fe;
outline: none;
background: white;
cursor: pointer;
letter-spacing: 2;
font-size: 12px;
text-transform: capitalize;
border-left: 1px solid #1f63fe;
-webkit-transition: all 0.3s;
transition: all 0.3s;
border-radius: 6px !important;
border:transparent;
}
.react-tabs__tab-list{
    border-bottom:transparent !important;
    padding-top: 12px !important;
    padding-left: 12px !important;
    border-radius: 6px !important;
}