.component-login{
    padding:5% 10%;
    .title{
        letter-spacing: 1px;
        line-height: 1.5;
    }
    .left-container{
        background-position:bottom;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 1;
        .overlay{
            position:absolute;
            color:white;
            width:100%;
            height:100%;
            left:0;
            top:0;
            z-index: 2;
            background:  linear-gradient(140deg, rgba(37, 157, 246, 0.5), #206afd);
        }
    }
    .form-container{
        max-width:1146px;
        max-height:700px;
        .bottom-text{
            font-size:38px;
        }
    }
}